<template>
    <div>
        <div>
            <h2>
                Geographic Targeting
            </h2>
            <p class="mb-5">
                Enter your geographic targeting preferences for each ad set in this play or use a global radius that will apply to all ad sets within the play.
            </p>
            <div class="layout wrap mb-5">
                <div class="flex xs12 md6 pa-2">
                    <v-form v-model="valid">
                        <v-switch
                            v-model="singleRadius"
                            label="Single Radius"
                            color="light-blue"
                            class="justify-center px-3 my-0" />
                        <template v-if="!singleRadius">
                            <v-layout
                                v-for="(adSet, index) in adSets"
                                :key="adSet.remote_name"
                                row>
                                <v-flex xs12>
                                    <v-text-field
                                        :value="adSet.radius"
                                        :label="adSet.name"
                                        class="py-3 my-0"
                                        type="number"
                                        suffix="miles"
                                        :rules="[...requiredRules, ...hecRadiusRules]"
                                        light
                                        @input="updateRadius($event, index)"
                                        @focus="activeRadiusIndex = index" />
                                </v-flex>
                            </v-layout>
                        </template>
                        <template v-if="singleRadius">
                            <v-layout class="">
                                <v-flex
                                    xs12>
                                    <v-text-field
                                        v-model="singleRadiusValue"
                                        type="number"
                                        label="Radius"
                                        suffix="miles"
                                        :rules="[...requiredRules, ...hecRadiusRules]"
                                        light />
                                </v-flex>
                            </v-layout>
                        </template>
                    </v-form>
                </div>
                <div class="flex xs12 md6 pa-2">
                    <gmap-map
                        ref="map"
                        :center="coordinates"
                        :zoom="zoom"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: true
                        }"
                        :style="{ width: '100%', height: '100%', 'min-height': '200px' }">
                        <gmap-marker
                            ref="marker"
                            :icon="google && {
                                path: google.maps.SymbolPath.CIRCLE,
                                fillColor: colors.red,
                                fillOpacity: 1,
                                strokeWeight: 0,
                                scale: 6
                            }"
                            :position="coordinates" />
                    </gmap-map>
                </div>
            </div>
        </div>
        <div class="layout align-center">
            <back-button
                @click="$store.dispatch('goToPreviousStep')" />
            <styled-button
                class="ml-auto"
                :disabled="!valid"
                @click="$store.dispatch('goToNextStep')">
                CONTINUE
            </styled-button>
        </div>
    </div>
</template>

<script>
import BackButton from '../../../globals/BackButton';
import StyledButton from '../../../globals/StyledButton';
import { mapState } from 'vuex';
import validationRules from '../../../../helpers/validationRules';
import GmapMap from 'vue2-google-maps/src/components/map';
import GmapMarker from 'vue2-google-maps/src/components/marker';
import { gmapApi } from 'vue2-google-maps';
import colors from '../../../../helpers/colors';
import { UPDATE_ONBOARDING_DATA } from '../../../../store/mutation-types';
import { get } from 'lodash';

export default {
    name: 'OnboardingPlayTargeting',
    components: {
        BackButton,
        StyledButton,
        GmapMap,
        GmapMarker
    },
    data() {
        return {
            valid: false,
            coordinates: null,
            zoom: 15,
            adSets: [],
            singleRadius: true,
            singleRadiusValue: 15,
            requiredRules: validationRules.requiredRules,
            hecRadiusRules: validationRules.hecRadiusRules,
            activeRadiusIndex: 0,
            milesPerMeter: 0.00062137,
            colors
        };
    },
    computed: {
        ...mapState({
            currentDealer: (state) => state.dealer.currentDealer,
            playAdSets: (state) => state.onboarding.playAdSets,
            onboardingData: (state) => state.onboarding.data,
        }),
        currentRadius() {
            if (this.singleRadius) {
                return this.singleRadiusValue / this.milesPerMeter;
            }

            return this.adSets[this.activeRadiusIndex].radius / this.milesPerMeter;
        },
        google: gmapApi
    },
    watch: {
        singleRadius(isSingle) {
            if (isSingle) {
                this.adSets = this.adSets.map(adSet => {
                    adSet.radius = parseInt(this.singleRadiusValue);
                    return adSet;
                });
            }
        },
        singleRadiusValue(value) {
            this.adSets = this.adSets.map(adSet => {
                adSet.radius = parseInt(value);
                return adSet;
            });
        },
        adSets: {
            deep: true,
            handler(adSets) {
                this.$store.commit(UPDATE_ONBOARDING_DATA, { adSets });
            }
        },
        currentRadius(value) {
            this.setRadius(value);
        }
    },
    created() {

        this.coordinates = {
            lat: parseFloat(this.currentDealer.latitude),
            lng: parseFloat(this.currentDealer.longitude)
        };

        // If there's existing data and it has the right number of campaigns use it
        const existingAdSets = get(this.onboardingData, 'adSets', null) || [];
        if (existingAdSets.length == this.playAdSets.length) {
            this.adSets = existingAdSets;
            // Determine if all radii match the first
            this.singleRadius = this.adSets.every(adSet => {
                return adSet.radius == this.adSets[0].radius;
            });

            // If there's a single radius init the value with the first
            if (this.singleRadius) {
                this.singleRadiusValue = this.adSets[0].radius;
            }
        }

        // Otherwise copy over the ad sets & prime the value with the minimum
        else {
            this.adSets = this.playAdSets.map(adSet => {
                adSet.radius = adSet.minimum_radius;
                return adSet;
            });
        }
    },
    mounted() {
        this.initMap();
    },
    methods: {
        updateRadius(value, index) {
            // Ensure a state update occurs
            const newAdSets = [ ...this.adSets ];
            newAdSets[index].radius = (value && isFinite(value)) ? parseInt(value) : value;
            this.adSets = newAdSets;
        },
        async initMap() {
            this.circle = null;
            await this.$refs.marker.$markerPromise;
            this.initCircle();
        },
        initCircle() {
            // eslint-disable-next-line
            this.circle = new google.maps.Circle({
                map: this.$refs.map.$mapObject,
                radius: this.currentRadius,
                fillColor: colors.red,
                strokeColor: colors.red,
                strokeOpacity: 0.5,
                strokeWeight: 1,
            });
            this.circle.bindTo('center', this.$refs.marker.$markerObject, 'position');

            this.setRadius(this.currentRadius);
        },
        setRadius(radius) {
            if (!radius || !this.circle) {
                return;
            }

            this.circle.setRadius(radius);

            // eslint-disable-next-line
            const bounds = new google.maps.LatLngBounds();
            bounds.union(this.circle.getBounds());

            this.$refs.map.$mapObject.fitBounds(bounds);
        }
    }
};
</script>
