var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('h2',[_vm._v(" Geographic Targeting ")]),_c('p',{staticClass:"mb-5"},[_vm._v(" Enter your geographic targeting preferences for each ad set in this play or use a global radius that will apply to all ad sets within the play. ")]),_c('div',{staticClass:"layout wrap mb-5"},[_c('div',{staticClass:"flex xs12 md6 pa-2"},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-switch',{staticClass:"justify-center px-3 my-0",attrs:{"label":"Single Radius","color":"light-blue"},model:{value:(_vm.singleRadius),callback:function ($$v) {_vm.singleRadius=$$v},expression:"singleRadius"}}),(!_vm.singleRadius)?_vm._l((_vm.adSets),function(adSet,index){return _c('v-layout',{key:adSet.remote_name,attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{staticClass:"py-3 my-0",attrs:{"value":adSet.radius,"label":adSet.name,"type":"number","suffix":"miles","rules":[..._vm.requiredRules, ..._vm.hecRadiusRules],"light":""},on:{"input":function($event){return _vm.updateRadius($event, index)},"focus":function($event){_vm.activeRadiusIndex = index}}})],1)],1)}):_vm._e(),(_vm.singleRadius)?[_c('v-layout',{},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-text-field',{attrs:{"type":"number","label":"Radius","suffix":"miles","rules":[..._vm.requiredRules, ..._vm.hecRadiusRules],"light":""},model:{value:(_vm.singleRadiusValue),callback:function ($$v) {_vm.singleRadiusValue=$$v},expression:"singleRadiusValue"}})],1)],1)]:_vm._e()],2)],1),_c('div',{staticClass:"flex xs12 md6 pa-2"},[_c('gmap-map',{ref:"map",style:({ width: '100%', height: '100%', 'min-height': '200px' }),attrs:{"center":_vm.coordinates,"zoom":_vm.zoom,"options":{
                        zoomControl: true,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: false,
                        fullscreenControl: false,
                        disableDefaultUi: true
                    }}},[_c('gmap-marker',{ref:"marker",attrs:{"icon":_vm.google && {
                            path: _vm.google.maps.SymbolPath.CIRCLE,
                            fillColor: _vm.colors.red,
                            fillOpacity: 1,
                            strokeWeight: 0,
                            scale: 6
                        },"position":_vm.coordinates}})],1)],1)])]),_c('div',{staticClass:"layout align-center"},[_c('back-button',{on:{"click":function($event){return _vm.$store.dispatch('goToPreviousStep')}}}),_c('styled-button',{staticClass:"ml-auto",attrs:{"disabled":!_vm.valid},on:{"click":function($event){return _vm.$store.dispatch('goToNextStep')}}},[_vm._v(" CONTINUE ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }